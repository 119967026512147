<template>
  <div>
    <router-view class="main-cont" />
  </div>
</template>

<script>
export default {
  name: "layout-embed",
  metaInfo() {
    return {
      title: "",
      titleTemplate: (title) =>
        title ? `${title} • INCREDEVENT` : "INCREDEVENT",
    };
  },
};
</script>
